<style scoped lang="scss">
.head-right {
  :deep(.el-input__inner) {
    color: #fff !important;
  }
}
@media screen and (min-width: 769px) {
  .list-word {
    // width: 100%;
    // display: flex;
    // flex-wrap: wrap;
    padding-top: 20px !important;
    grid-template-columns: repeat(auto-fill, minmax(220px, 2fr));
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 25px;
    padding: 0 10rem;
    background: linear-gradient(180deg, lightblue, #fff, #fff);
  }

  .showMoreButtonCont {
    text-align-last: center;
    margin-top: 20px;
  }

  .button {
    font-size: 18px;
    font-weight: 500;
    padding: 10px 18px;
    border-radius: 4px;
    background-color: rgb(189, 219, 228);
    border: 1px solid #ffff;
    cursor: pointer;
    margin: 0 5px;
    box-shadow: rgb(90, 181, 209) 0px -3px 0px inset;
  }
}

@media screen and (max-width: 768px) {
  .head-right {
    display: none !important;
  }
  .moblie {
    display: none;
  }
  .have-List {
    padding: 10px !important;
  }

  .content {
    padding: 0.5rem;
  }

  .list-word {
    grid-template-columns: repeat(2, minmax(0, 1fr));

    grid-column-gap: 10px;
    grid-row-gap: 10px;
    display: grid;
  }

  .showMoreButtonCont {
    text-align-last: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .button {
    font-size: 16px;
    font-weight: 500;
    padding: 10px 18px;
    border-radius: 4px;
    background-color: rgb(189, 219, 228);
    border: 1px solid #ffff;
    cursor: pointer;
  }
}
</style>
<template>
  <div>
    <div>
      <navbar />
    </div>
    <div class="middle">
      <div class="middle-head">
        <div class="head-left moblie">
          <span class="left" @click="goPage">Home</span>
          <span><svg-icon icon="arrow-right"></svg-icon></span>
          <span class="left-frite">History</span>
        </div>
        <!-- <div class="head-right">
          <el-input v-model="searchVal" placeholder="Please input" class="input-with-select">
            <template #append>
              <el-button :icon="Search" @click="gotoIndexSearch" />
            </template>
          </el-input>
        </div> -->
      </div>
      <div class="content">
        <!-- <div class="have-List">
          <ul class="list-word">

            <cardBody
              :cardObject="item"
              v-for="(item, index) in historyList"
              :key="index"
            ></cardBody>
          </ul>
        </div> -->

        <div class="list-word" v-if="historyList.length == 0 && loading">
          <div v-for="(item, index) in 4" :key="index">
            <el-skeleton style="width: 240px" animated>
              <template #template>
                <el-skeleton-item variant="image" style="width: 240px; height: 240px" />
                <div style="padding: 14px">
                  <el-skeleton-item variant="p" style="width: 50%" />
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      justify-items: space-between;
                    "
                  >
                    <el-skeleton-item variant="text" style="margin-right: 16px" />
                    <el-skeleton-item variant="text" style="width: 30%" />
                  </div>
                </div>
              </template>
            </el-skeleton>
          </div>
        </div>

        <ul class="list-word" id="moblieList" v-if="historyList != 0" @scroll="scrolling">
          <cardBody
            :cardObject="item"
            v-for="(item, index) in historyList"
            :key="index"
          ></cardBody>
        </ul>
        <div class="noList" v-if="historyList.length == 0">
          <h3>No Favorites Yet</h3>
          <p><span @click="goPage">show more Experts</span></p>
        </div>
        <div
          v-if="showmorecontent == 'Show More'"
          class="showMoreButtonCont"
        >
          <b-button class="button" @click="showMore">{{ showmorecontent }}</b-button>
        </div>
        <!-- <div
          v-else
          style="color: rgb(182, 182, 182); text-align-last: center; margin-top: 20px"
        >
          <a @click="showMore">{{ showmorecontent }}</a>
        </div> -->
      </div>

      <div v-if="store1.isSide">
        <Sidebar />
      </div>
    </div>
    <div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script setup>
import Sidebar from "@/layout/components/Sidebar/index.vue";
import { phoneStore } from "../../store/phone1";
import cardBody from "@/components/cardBody";
import Navbar from "../.././layout/components/Navbar.vue";
import Footer from "../.././layout/components/Footer.vue";
import { getCurrentInstance, onMounted, ref } from "vue";
import { ElMessage, ElNotification } from "element-plus";
import { Search } from "@element-plus/icons-vue";
import { setToken, getToken, removeToken } from "@/utils/auth";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import request from "@/utils/request.js";
import bus from "vue3-eventbus";
const { $t } = getCurrentInstance().proxy;
const router = useRouter();
const store = useStore();
const ImageUrl = process.env.VUE_APP_BASE_ImageUrl;
const showmorecontent = ref("Show More");
const historyList = ref([]);
const searchVal = ref("");
const store1 = phoneStore();
const loading = ref(true);
onMounted(() => {
  store1.isSide = false;
  getFavorite();
});

const goPage = () => {
  router.push("/onlineExpert");
};
function gotoIndexSearch() {
  router.push("/onlineExpert?q=" + searchVal.value);
}
const pageNum = ref(1);
const pageSize = ref(10);
async function getFavorite() {
  let params = {
    pageNum: pageNum.value,
    pageSize: pageSize.value,
  };
  const { code, rows, total } = await request.get("/system/FaUser/getMyViewHistory", {
    params: params,
  });
  // rows.forEach(item => {
  //   item.collect = '1'
  // })
  console.log("rows", rows);
  if (code == 200) {
    loading.value = false;
  }
  if (code == 200 && pageNum.value <= Math.ceil(total / pageSize.value)) {
    historyList.value = historyList.value.concat(rows);
  }
  if (pageNum.value >= Math.ceil(total / pageSize.value)) {
    showmorecontent.value = "no more";
  }
  // if (code == 200) {
  //   historyList.value = rows
  // }
}
// 点击进入详情页面
const goDetailPage = (id, item) => {
  router.push("/detailPage/" + id);
};
// 展示更多
function showMore() {
  if (showmorecontent.value == "no more") {
    ElMessage("no more.");
    return;
  }
  pageNum.value += 1;
  getFavorite();
}
</script>

<style lang="scss" scoped>
.filtergly {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray !important;
}

.middle {
  width: 100%;
  background: #ffffff;
  padding-bottom: 46px;

  .middle-head {
    line-height: 30px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ffff;
    border-color: rgba(251, 249, 249, 0.1);
    font-size: 12px;
    padding: 0 10rem;

    .head-left {
      margin-left: 10px;
      color: #000000;
      font-size: 16px;

      .left {
        padding-right: 5px;
        cursor: pointer;
      }

      .left-frite {
        color: #eedf11;
      }
    }

    .head-right {
      margin-right: 8px;
      display: flex;

      :deep(.el-input__wrapper) {
        background-color: #607e83;
        box-shadow: none;
      }

      span {
        font-size: 20px;
        cursor: pointer;
        margin-left: 8px;
        margin-right: 15px;
        padding-right: 8px;
        border-right: 1px solid rgba(251, 249, 249, 0.1);
      }
    }
  }

  .content {
    min-height: 600px;

    .have-List {
      // padding: 30px;
      padding: 0 10rem;

      .list-content {
        margin: 15px;
        display: flex;
        flex-wrap: wrap;
      }

      .list-word {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
      }
    }

    .noList {
      text-align: center;
      padding-top: 100px;
      margin: auto;

      h3 {
        line-height: 30px;
        color: #ffff;
      }

      p {
        margin-top: 15px;
      }

      span {
        padding: 7px;
        background-color: #f1f507;
        font-size: 12px;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
}

.show-more {
  color: #ffe400 !important;
  display: block;
  padding-top: 8px;
  margin: 0 auto;
  clear: both;
  overflow: hidden;
  text-align: center;
  font-size: 12px;
  line-height: 10px;
  cursor: pointer;
  text-transform: capitalize;
  font-family: Poppins;
}

.show-more:before {
  right: 16px;
  margin-left: -50%;
}

.show-more:after {
  left: 16px;
  margin-right: -50%;
}

.show-more:before,
.show-more:after {
  content: "";
  position: relative;
  display: inline-block;
  width: 50%;
  height: 1px;
  vertical-align: middle;
  background-color: #ffffff2a;
}
</style>
